<template>
    <v-container>
        <v-row>
            <v-breadcrumbs large :items="items_nav">
                <template v-slot:divider>
                <v-icon>mdi-chevron-right</v-icon>
                </template>
                <template v-slot:item="{ item }">
                <v-breadcrumbs-item :disabled="item.disabled">
                    {{ item.text }}
                </v-breadcrumbs-item>
                </template>
            </v-breadcrumbs>
        </v-row>
        <v-card elevation="4">
            <v-card-title>
                <v-row>
                    <div class="col">
                        Perfil do Fornecedor
                    </div>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-form ref="form" lazy-validation>
                  <!-- <v-row>
                    <v-col cols="12"  md="4" >
                      <v-card elevation="4" height="160" width="160">
                        <v-container grid-list-sm fluid>
                          <v-layout row wrap>
                            <v-img
                              aspect-ratio="1"
                            >              
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                              >
                                <v-progress-circular
                                  indeterminate
                                  color="white lighten-5"
                                ></v-progress-circular>
                              </v-row>
                            </template>        
                            </v-img>
                          </v-layout>
                        </v-container>
                      </v-card>
                    </v-col>
                  </v-row> -->
                    <v-row>
                        <v-col cols="12"  md="6" class="text-center">
                            <v-text-field
                                v-model="fornecedor.nome_empresa"
                                counter="40"
                                maxlength="40"
                                label="Nome da Empresa"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-select
                            v-model="fornecedor.categoria_id"
                            item-text="nome"
                            item-value="id"
                            :items="getCategorias"
                            label="Categoria"
                            
                          >
                          </v-select>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12"  md="8" class="text-center">
                            <v-text-field
                                v-model="fornecedor.mensagem"
                                counter="50"
                                maxlength="50"
                                label="Mensagem"
                                required
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12"  md="4" class="text-center">
                            <v-row>
                                <v-col cols="12" md="8">
                                    <v-text-field
                                        v-model="fornecedor.tag"
                                        counter="20"
                                        maxlength="20"
                                        label="Tag"
                                        required
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="12" md="4" align-self="center">
                                    <v-hover>
                                        <template v-slot:default="{ hover }">
                                           <v-avatar
                                            :color="'#'.concat(fornecedor.color_tag)"
                                            size="36">
                                            <v-fade-transition>
                                                <v-overlay
                                                    v-if="hover"
                                                    absolute
                                                >
                                                    <v-btn icon @click="pickColor('#'.concat(fornecedor.color_tag))"><v-icon>mdi-pencil</v-icon></v-btn>
                                                </v-overlay>
                                            </v-fade-transition>
                                            
                                        </v-avatar>
                                        
                                    </template>                                   
                                    </v-hover>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12"  md="12" class="text-center">
                            <v-textarea
                                v-model="fornecedor.descricao"
                                label="Descrição"></v-textarea>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12"  md="4" class="text-center">
                            <v-text-field
                                v-model="fornecedor.whatsapp"
                                counter="16"
                                maxlength="16"
                                label="Whatsapp"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12"  md="4" class="text-center">
                            <v-text-field
                                v-model="fornecedor.telefone"
                                counter="16"
                                maxlength="16"
                                label="Telefone"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12"  md="4" class="text-center">
                          <v-select
                            v-model="fornecedor.uf"
                            item-text="id"
                            item-value="nome"
                            :items="getUFs"
                            label="UF"
                            return-object
                          >
                          </v-select>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12"  md="12" class="text-center">
                            <v-text-field
                                v-model="fornecedor.localizacao.endereco"
                                counter="120"
                                maxlength="120"
                                label="Endereço"
                                required>
                                </v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12"  md="6" class="text-center">
                            <v-text-field
                                v-model="fornecedor.localizacao.latitude"
                                label="Latitude"
                                type="number"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                                v-model="fornecedor.localizacao.longitude"
                                label="Longitude"
                                type="number"
                                required
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12"  md="6" class="text-center">
                            <v-text-field
                                v-model="novo_pacote"
                                counter="40"
                                maxlength="40"
                                label="Novo pacote"
                                required
                                append-icon="mdi-plus-box"
                                @click:append="addPacote()"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                                v-model="nova_promocao"
                                counter="40"
                                maxlength="40"
                                label="Nova promoção"
                                required
                                append-icon="mdi-plus-box"
                                @click:append="addPromocao()"
                            ></v-text-field>
                        </v-col>
                    </v-row>


                    <v-row>
                        <v-col cols="12"  md="6">
                            <v-simple-table>
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                        <th class="text-left">
                                            Descrição de Pacote
                                        </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                        v-for="item in fornecedor.pacotes"
                                        :key="item"
                                        >
                                        <td>{{ item }}</td>
                                        <td>
                                            <v-btn
                                                icon
                                                @click="removePacote(item)">
                                                <v-icon>mdi-close</v-icon>
                                            </v-btn>
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-col>
                        <v-col cols="12"  md="6">
                            <v-simple-table>
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                        <th class="text-left">
                                            Descrição de Promoção
                                        </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                        v-for="item in fornecedor.promocoes"
                                        :key="item"
                                        >
                                        <td>{{ item }}</td>
                                        <td>
                                            <v-btn
                                                icon
                                                @click="removePromocao(item)">
                                                <v-icon>mdi-close</v-icon>
                                            </v-btn>
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12"  md="12">
                            <v-file-input
                                v-model="imagem_upload"
                                :rules="rulesImagem"
                                accept="image/png, image/jpeg"
                                placeholder="Adicionar imagem"
                                prepend-icon="mdi-camera"
                                append-icon
                                show-size
                                @change="uploadImagem()"
                            ></v-file-input>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col
                            v-for="url in fornecedor.fotos"
                            :key="url"
                            class="d-flex child-flex"
                            cols="4"
                        >
                        <v-hover>
                            <template v-slot:default="{ hover }">
                                <v-img
                                :src="url"
                                aspect-ratio="1"
                                class="grey lighten-2"
                            >
                                <template v-slot:placeholder>
                                <v-row
                                    class="fill-height ma-0"
                                    align="center"
                                    justify="center"
                                >
                                    <v-progress-circular
                                    indeterminate
                                    color="grey lighten-5"
                                    ></v-progress-circular>
                                </v-row>

                                </template>
                                <v-fade-transition>
                                    <v-overlay
                                        v-if="hover"
                                        absolute
                                        color="#FF6358"
                                    >
                                        <v-btn icon @click="deleteImagem(url)"><v-icon x-large>mdi-delete</v-icon></v-btn>
                                    </v-overlay>
                                </v-fade-transition>
                                
                            </v-img>
                            
                            </template>
                            
                        </v-hover>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-action class="d-flex flex-row-reverse">
                <div class="ma-4">
                        <v-btn
                            color="success"
                            dark
                            @click="salvar()"
                            >
                            SALVAR
                        </v-btn>
                    </div>
            </v-card-action>
        </v-card>

        <v-dialog v-model="dialogProgress" hide-overlay persistent width="300">
          <v-card color="primary" dark>
            <v-card-text>
              Realizando operação por favor aguarde...
              <v-progress-linear
                indeterminate
                color="white"
                class="mb-0"
              ></v-progress-linear>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogMessage" width="480">
          <v-card class="mx-auto" dark>
            <v-card-title>
                <span class="title font-weight-light">{{ message.title }}</span>
            </v-card-title>
            <v-card-text class="headline font-weight-bold">
                {{ message.body }}
            </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogColor" max-width="480">
          <v-card class="mx-auto" dark>
              <v-card-text>
                <v-color-picker
                    v-model="color_tag"
                    dot-size="25"
                    hide-inputs
                    hide-sliders
                    mode="hexa"
                    show-swatches
                    swatches-max-height="200"
                    ></v-color-picker>
              </v-card-text>
              <v-card-action class="d-flex flex-row-reverse">
                  <div class="ma-4">
                        <v-btn
                            color="success"
                            dark
                            @click="saveColor()"
                            >
                            SALVAR
                        </v-btn>
                  </div>
              </v-card-action>
        </v-card>
        </v-dialog>

    </v-container>
</template>

<script>
import database from "@/services/database";
import { mapGetters } from "vuex";

export default {
    data() {
        return {
            items_nav: [
                {
                    text: "Sistema",
                    disabled: false,
                },
                {
                    text: "Fornecedores",
                    disabled: true,
                },
                {
                    text: "Perfil",
                    disabled: true,
                }
            ],
            rulesImagem: [
                value => !value || value.size < 2000000 || 'Tamanho da imagem deve ser inferior a 2 MB!',
            ],
            novo_pacote: "",
            nova_promocao: "",
            imagem_upload: null,
            color_tag: "#FFFFFF",
            dialogColor: false,
            fornecedor: {
                categoria_id: "",
                descricao: "",
                mensagem: "",
                nome_empresa: "",
                tag: "",
                telefone: "",
                uf: "",
                whatsapp: "",
                localizacao: {
                    endereco: "",
                    latitude: 0,
                    longitude: 0
                },
                pacotes: [],
                promocoes: [],
                fotos: []
            },
            loading: false,
            dialogProgress: false,
            message: {
              title: "",
              body: ""
            },
            dialogMessage: false,
      }
    },
    computed: {
        ...mapGetters(["getCategorias", "getUFs"]),
    },
    mounted() {
        this.getDataFromApi();
    },
    methods: {
        getDataFromApi: async function() {
      this.dialogProgress = true;
      var data = await database.getFornecedorById(this.$route.params.id);
      this.fornecedor = data;
      this.dialogProgress = false;
    },
      salvar: async function() {
        this.dialogProgress = true;
        var result;
        result = await database.updateFornecedor(this.fornecedor);
        this.dialogProgress = false;
        if(result) {
          this.dialogProgress = false;
          this.message.title = "Operação realizada";
          this.message.body = "Alteração realizada com sucesso!";
          this.dialogMessage = true;
        } else {
          this.dialogProgress = false;
            this.message.title = "Operação falhou";
            this.message.body = "Verifique sua conexão e tente novamente!";
            this.dialogMessage = true;
        }

      },
      addPacote: function() {
          this.fornecedor.pacotes.push(this.novo_pacote);
          this.novo_pacote = "";
      },
      addPromocao: function() {
          this.fornecedor.promocoes.push(this.nova_promocao);
          this.nova_promocao = "";
      },
      removePacote: function(item) {
        for( var i = 0; i < this.fornecedor.pacotes.length; i++){ 
            if ( this.fornecedor.pacotes[i] === item) { 
                this.fornecedor.pacotes.splice(i, 1); 
            }
        }
      },
      removePromocao: function(item) {
        for( var i = 0; i < this.fornecedor.promocoes.length; i++){ 
            if ( this.fornecedor.promocoes[i] === item) { 
                this.fornecedor.promocoes.splice(i, 1); 
            }
        }
      },
      saveImagem: async function(image) {
        var result = await database.uploadImagemFornecedor(
            image,
            this.imagem_upload.name.split(".")[1],
            this.fornecedor.id,
            this.imagem_upload.name.split(".")[0]
        );
        this.fornecedor.fotos.push(result.url);
        this.imagem_upload = null;
        this.dialogProgress = false;
      },
      uploadImagem: async function() {
        const reader = new FileReader();
        this.dialogProgress = true;
        var image = null;
        reader.readAsDataURL(this.imagem_upload);
        if(this.imagem_upload == null) {
            return;
        }
        reader.onload = (e) => {
            image = e.target.result;
        };
        reader.onloadend = async () => await this.saveImagem(image);
        
        },
        deleteImagem: function(item) {
          for( var i = 0; i < this.fornecedor.fotos.length; i++){ 
            if ( this.fornecedor.fotos[i] === item) { 
                this.fornecedor.fotos.splice(i, 1); 
            }
          }
        },
        pickColor: function(color) {
            this.color_tag = '#'.concat(color).concat('FF');
            this.dialogColor = true;
        },
        saveColor: async function() {
            this.fornecedor.color_tag = this.color_tag.slice(1, 7);
            this.dialogColor = false;
        },
    },
}
</script>

<style>

</style>